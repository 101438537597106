<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>list</md-icon>
            <h3 class="pl-2 text-uppercase">Blog share</h3>
          </div>
          <div class="flex">
           <md-button class="outline-gray-400 rounded ">
             <md-icon>date_range</md-icon> 28 March, 2020
           </md-button>
           <md-icon>remove</md-icon>
           <md-button class="outline-gray-400 rounded ">
             <md-icon>date_range</md-icon> 28 March, 2020
           </md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <SelectComponent placeholder="All" class="w-64 m-2" />
        </div>
        <md-divider></md-divider>
        <Table :items="blogSharing" @onSelect="onSelect" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="blogId" md-label="BLOG ID" class="text-victoria">{{ item.blogId }}</md-table-cell>
            <md-table-cell md-sort-by="blogIndex" md-label="BLOG INDEX" class="text-victoria">{{ item.blogIndex }}</md-table-cell>
            <md-table-cell md-sort-by="relatedOn" md-label="RELATED ON">{{ item.relatedOn }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-sort-by="views" md-label="VIEWS">{{ item.views }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">edit</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mr-1">delete</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
    <Dialog>
      <CreateNewNotice />
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    SelectComponent
    } from "@/components";
import CreateNewNotice from "@/components/molecule/communication/CreateNewNotice";
import { blogSharing } from "@/data/communication/popup";
import { mapMutations } from "vuex";

export default {
    components: {
        Card,
        Table,
        Dialog,
        CardBody,
        SelectComponent,
        CreateNewNotice
  },
  data() {
      return {
          blogSharing: blogSharing
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onSelect() {

    }
  },
}
</script>